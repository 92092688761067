const messages = {
  fa: {
    translations: {
      signup: {
        title: "ثبت و نام",
        toasts: {
          success:
            "کاربر با موفقیت ایجاد شد! لطفا وارد شوید!",
          error: "کاربر با موفقیت ایجاد نشد دوباره تلاش کن!",
          fail: "خطا در ایجاد کاربر. داده های گزارش شده را بررسی کنید.",
        },
        form: {
          name: "اسم",
          email: "ایمیل",
          password: "رمز عبور",
        },
        buttons: {
          submit: "ثبت نام",
          login: "از قبل حساب کاربری دارید؟ وارد شدن!",
        },
      },
      login: {
        title: "ورود",
        form: {
          email: "ایمیل",
          password: "پسورد",
        },
        buttons: {
          submit: "ورود",
          register: "حساب کاربری ندارید؟ ثبت نام!",
        },
      },
      auth: {
        toasts: {
          success: "با موفقیت وارد شوید",
          error: "رمز عبور یا ایمیل شما معتبر نیست"

        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "تیکت امروز:",
          },
        },
        messages: {
          inAttendance: {
            title: "خدمات"
          },
          waiting: {
            title: "در انتظار"
          },
          closed: {
            title: "بسته شد"
          }
        }
      },
      connections: {
        title: "اتصالات",
        Buttons: {
          qrcode: "اسکن",
          tryAgain: "تلاش دوباره",
          newQr: "qr جدید",
          disconnect: "قطع شدن",
          connecting: "برقراری ارتباط"
        },
        toasts: {
          deleted:
            "اتصال واتساپ با موفقیت حذف شد!",
          error: "اتصال واتساپ حذف نشد دوباره تلاش کن"
        },
        confirmationModal: {
          deleteTitle: "حذف",
          deleteMessage: "مطمئنی؟ نمی توان آن را برگرداند.",
          disconnectTitle: "قطع شدن",
          disconnectMessage: "مطمئنی؟ شما باید کد QR را دوباره بخوانید.",
          noBTN: "مخالفم",
          okBTN: "موافقم"
        },
        buttons: {
          add: "افزودن واتساپ",
          disconnect: "قطع ارتباط",
          tryAgain: "دوباره امتحان کنید",
          qrcode: "کد QR",
          newQr: "کد QR جدید",
          connecting: "در حال اتصال",
        },
        toolTips: {
          disconnected: {
            title: "عدم موفقیت در شروع جلسه واتساپ",
            content:
              "مطمئن شوید تلفن همراه شما به اینترنت متصل است و دوباره امتحان کنید یا درخواست کد QR جدیدی ارسال کنید",
          },
          qrcode: {
            title: "در انتظار خواندن کد QR",
            content:
              "روی دکمه 'کد QR' کلیک کنید و با تلفن همراه خود کد QR را بخوانید تا جلسه شروع شود",
          },
          connected: {
            title: "اتصال برقرار شد",
          },
          timeout: {
            title: "ارتباط با تلفن همراه قطع شده است",
            content:
              "مطمئن شوید تلفن همراه شما به اینترنت متصل است و واتساپ باز است یا بر روی دکمه 'قطع ارتباط' کلیک کنید تا کد QR جدید دریافت کنید",
          },
        },
        table: {
          name: "نام",
          status: "وضعیت",
          lastUpdate: "آخرین بروزرسانی",
          default: "پیشفرض",
          actions: "عملیات",
          session: "جلسه",
        },
      },
      whatsappModal: {
        title: {
          add: "افزودن واتساپ",
          edit: "ویرایش واتساپ",
        },
        form: {
          name: "نام",
          default: "پیشفرض",
          farewellMessage: "پیام خداحافظی"
        },
        buttons: {
          okAdd: "افزودن",
          okEdit: "ذخیره",
          cancel: "لغو",
        },
        success: "واتساپ با موفقیت ذخیره شد.",
      },
      qrCode: {
        message: "برای شروع نشست، کد QR را اسکن کنید",
      },

      contacts: {
        title: "مخاطبین",
        toasts: {
          deleted: "مخاطب با موفقیت حذف شد!",
          error: "مخاطب مورد نظر حذف نشد"
        },
        searchPlaceholder: "جستجو کنید...",
        confirmationModal: {
          deleteTitle: "حذف",
          importTitlte: "ورود مخاطبان",
          deleteMessage:
            "آیا از حذف این مخاطب اطمینان دارید؟ تمامی تیکت‌های مرتبط نیز حذف خواهند شد.",
          importMessage: "آیا میخواهید تمامی مخاطبان را از تلفن وارد کنید؟",
        },
        buttons: {
          import: "ورود مخاطبان",
          add: "افزودن مخاطب",
        },
        table: {
          name: "نام",
          whatsapp: "واتساپ",
          email: "ایمیل",
          actions: "عملیات",
        },
      },

      contactModal: {
        title: {
          add: "افزودن مخاطب",
          edit: "ویرایش مخاطب",
        },
        form: {
          mainInfo: "جزئیات مخاطب",
          extraInfo: "اطلاعات اضافی",
          name: "نام",
          number: "شماره واتساپ",
          email: "ایمیل",
          extraName: "نام فیلد",
          extraValue: "مقدار",
        },
        buttons: {
          addExtraInfo: "افزودن اطلاعات",
          okAdd: "افزودن",
          okEdit: "ذخیره",
          cancel: "لغو",
        },
        success: "مخاطب با موفقیت ذخیره شد.",
        error: "مشکلی پیش اومده بعدا تست کن"
      },

      quickAnswersModal: {
        title: {
          add: "افزودن پاسخ سریع",
          edit: "ویرایش پاسخ سریع",
        },
        form: {
          shortcut: "میانبر",
          message: "پاسخ سریع",
        },
        buttons: {
          okAdd: "افزودن",
          okEdit: "ذخیره",
          cancel: "لغو",
        },
        success: "پاسخ سریع با موفقیت ذخیره شد.",
      },
      queueModal: {
        title: {
          add: "افزودن صف",
          edit: "ویرایش صف",
        },
        form: {
          name: "نام",
          color: "رنگ",
          greetingMessage: "پیام خوشامدگویی",
        },
        buttons: {
          okAdd: "افزودن",
          okEdit: "ذخیره",
          cancel: "لغو",
        },
      },
      userModal: {
        title: {
          add: "افزودن کاربر",
          edit: "ویرایش کاربر",
        },
        form: {
          name: "نام",
          email: "ایمیل",
          password: "رمز عبور",
          profile: "پروفایل",
          واتساپ: "اتصال پیش‌فرض واتساپ",
        },
        buttons: {
          okAdd: "افزودن",
          okEdit: "ذخیره",
          cancel: "لغو",
        },
        success: "کاربر با موفقیت ذخیره شد.",
        error: "مشکلی پیش اومده بعدا تست کن"
      },

      chat: {
        noTicketMessage: "برای شروع چت، یک تیکت را انتخاب کنید.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "تیکت جدید",
        },
      },
      ticketsQueueSelect: {
        placeholder: "صف‌ها",
      },
      tickets: {
        toasts: {
          deleted: "تیکتی که در آن بودید حذف شده است.",
        },
        notification: {
          message: "پیام از طرف",
        },
        tabs: {
          open: { title: "صندوق ورودی" },
          closed: { title: "تیکت‌های حل شده" },
          search: { title: "جستجو" },
        },
        search: {
          placeholder: "جستجوی تیکت‌ها و پیام‌ها",
        },
        buttons: {
          showAll: "نمایش همه",
        },
      },

      transferTicketModal: {
        title: "انتقال تیکت",
        fieldLabel: "برای جستجوی کاربران تایپ کنید",
        fieldQueueLabel: "انتقال به صف",
        fieldConnectionLabel: "انتقال به ارتباط",
        fieldQueuePlaceholder: "لطفاً یک صف را انتخاب کنید",
        fieldConnectionPlaceholder: "لطفاً یک ارتباط را انتخاب کنید",
        noOptions: "هیچ کاربری با این نام یافت نشد",
        buttons: {
          ok: "انتقال",
          cancel: "لغو",
        },
      },
      ticketsList: {
        pendingHeader: "صف",
        assignedHeader: "در حال بررسی توسط",
        noTicketsTitle: "چیزی وجود ندارد!",
        noTicketsMessage: "تیکتی با این وضعیت یا عبارت جستجو یافت نشد.",
        connectionTitle: "ارتباطی که در حال حاضر استفاده می‌شود.",
        buttons: {
          accept: "پذیرش",
        },
      },
      newTicketModal: {
        title: "ایجاد تیکت",
        fieldLabel: "برای جستجوی مخاطب تایپ کنید",
        add: "افزودن",
        buttons: {
          ok: "ذخیره",
          cancel: "لغو",
        },
      },

      mainDrawer: {
        listItems: {
          dashboard: "داشبورد",
          connections: "ارتباط با دستگاه ها",
          tickets: "تیکت‌ها",
          contacts: "مخاطبین",
          quickAnswers: "پاسخ‌های سریع",
          queues: "صف‌ها",
          administration: "مدیریت",
          users: "کاربران",
          settings: "تنظیمات",
        },
        appBar: {
          user: {
            profile: "پروفایل",
            logout: "خروج",
          },
        },
      },
      notifications: {
        noTickets: "هیچ اطلاعیه‌ای موجود نیست.",
      },
      queues: {
        title: "صف‌ها",
        table: {
          name: "نام",
          color: "رنگ",
          greeting: "پیام خوشامدگویی",
          actions: "عملیات",
        },
        buttons: {
          add: "افزودن صف",
        },
        confirmationModal: {
          deleteTitle: "حذف",
          deleteMessage:
            "آیا مطمئن هستید؟ این عملیات قابل بازگشت نیست! تیکت‌های موجود در این صف‌ها باقی می‌مانند، اما به هیچ صفی اختصاص داده نمی‌شوند.",
        },
      },
      queueSelect: {
        inputLabel: "صف‌ها",
      },

      quickAnswers: {
        title: "پاسخ‌های سریع",
        table: {
          shortcut: "میانبر",
          message: "پاسخ سریع",
          actions: "عملیات",
        },
        buttons: {
          add: "افزودن پاسخ سریع",
        },
        toasts: {
          deleted: "پاسخ سریع با موفقیت حذف شد.",
          error: "پاسخ سریع با موفقیت حذف نشد.",

        },
        searchPlaceholder: "جستجو...",
        confirmationModal: {
          deleteTitle: "آیا از حذف این پاسخ سریع مطمئن هستید؟",
          deleteMessage: "این عملیات قابل بازگشت نیست.",
        },
      },
      users: {
        title: "کاربران",
        table: {
          name: "نام",
          email: "ایمیل",
          profile: "پروفایل",
          whatsapp: "اتصال پیش‌فرض واتساپ",
          actions: "عملیات",
        },
        buttons: {
          add: "افزودن کاربر",
        },
        toasts: {
          deleted: "کاربر با موفقیت حذف شد.",
          error: "رمز عبور یا ایمیل شما معتبر نیست"
        },
        confirmationModal: {
          deleteTitle: "حذف",
          deleteMessage:
            "تمام داده‌های کاربر از بین خواهد رفت. تیکت‌های باز کاربران به صف منتقل می‌شوند.",
        },
      },
      settings: {
        success: "تنظیمات با موفقیت ذخیره شد.",
        error: "  تنظیمات ذخیره نشد",
        title: "تنظیمات",
        settings: {
          userCreation: {
            name: "ایجاد کاربر",
            options: {
              enabled: "فعال",
              disabled: "غیرفعال",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "متصل به:",
          buttons: {
            return: "بازگشت",
            resolve: "حل شده",
            reopen: "بازگشایی",
            accept: "پذیرش",
          },
        },
      },

      messagesInput: {
        placeholderOpen: "پیام ...",
        placeholderClosed: "پیام ...",
        signMessage: "امضا",
      },
      contactDrawer: {
        header: "جزئیات تماس",
        buttons: {
          edit: "ویرایش تماس",
        },
        extraInfo: "اطلاعات دیگر",
      },
      ticketOptionsMenu: {
        delete: "حذف",
        transfer: "انتقال",
        confirmationModal: {
          title: "حذف تیکت #",
          titleFrom: "از طرف تماس ",
          message: "توجه! تمام پیام‌های مرتبط با تیکت حذف خواهند شد.",
        },
        buttons: {
          delete: "حذف",
          cancel: "انصراف",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "تأیید",
          cancel: "انصراف",
        },
      },
      messageOptionsMenu: {
        delete: "حذف",
        reply: "پاسخ",
        confirmationModal: {
          title: "حذف پیام؟",
          message: "این عملیات قابل بازگشت نیست.",
        },
      },

      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "حداقل باید یک اتصال واتساپ پیش‌فرض وجود داشته باشد.",
        ERR_NO_DEF_WAPP_FOUND:
          "هیچ واتساپ پیش‌فرضی یافت نشد. صفحه اتصال‌ها را بررسی کنید.",
        ERR_WAPP_NOT_INITIALIZED:
          "این نشست واتساپ مقدماتی نشده است. صفحه اتصال‌ها را بررسی کنید.",
        ERR_WAPP_CHECK_CONTACT:
          "امکان بررسی تماس واتساپ وجود ندارد. صفحه اتصال‌ها را بررسی کنید.",
        ERR_WAPP_INVALID_CONTACT: "این شماره واتساپ معتبر نیست.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "امکان دانلود رسانه از واتساپ وجود ندارد. صفحه اتصال‌ها را بررسی کنید.",
        ERR_INVALID_CREDENTIALS: "خطا در احراز هویت. لطفاً مجدداً تلاش کنید.",
        ERR_SENDING_WAPP_MSG:
          "خطا در ارسال پیام واتساپ. صفحه اتصال‌ها را بررسی کنید.",
        ERR_DELETE_WAPP_MSG: "امکان حذف پیام از واتساپ وجود ندارد.",
        ERR_OTHER_OPEN_TICKET:
          "برای این تماس در حال حاضر یک تیکت باز وجود دارد.",
        ERR_SESSION_EXPIRED: "نشست منقضی شده است. لطفاً وارد شوید.",
        ERR_USER_CREATION_DISABLED:
          "ایجاد کاربر توسط مدیر غیرفعال شده است.",
        ERR_NO_PERMISSION: "شما دسترسی به این منبع را ندارید.",
        ERR_DUPLICATED_CONTACT: "یک تماس با این شماره در حال حاضر وجود دارد.",
        ERR_NO_SETTING_FOUND: "هیچ تنظیمی با این شناسه یافت نشد.",
        ERR_NO_CONTACT_FOUND: "هیچ تماسی با این شناسه یافت نشد.",
        ERR_NO_TICKET_FOUND: "هیچ تیکتی با این شناسه یافت نشد.",
        ERR_NO_USER_FOUND: "هیچ کاربری با این شناسه یافت نشد.",
        ERR_NO_WAPP_FOUND: "هیچ واتساپی با این شناسه یافت نشد.",
        ERR_CREATING_MESSAGE: "خطا در ایجاد پیام در پایگاه داده.",
        ERR_CREATING_TICKET: "خطا در ایجاد تیکت در پایگاه داده.",
        ERR_FETCH_WAPP_MSG:
          "خطا در دریافت پیام در واتساپ، شاید پیام به‌روز نیست.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "این رنگ قبلاً استفاده شده است، یک رنگ دیگر انتخاب کنید.",
        ERR_WAPP_GREETING_REQUIRED:
          "پیام خوش‌آمدگویی در صورت وجود بیش از یک صف الزامی است.",
      },
    },
  },
};

export { messages };
