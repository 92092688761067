import React from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import './font/Fa/fontFarsi.css'
import { useGetDIR } from "./hooks/useGetLanguages";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import createCache from '@emotion/cache';
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { ReplyMessageProvider } from "./context/ReplyingMessage/ReplyingMessageContext";


const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, stylisRTLPlugin],
});
const cacheLtr = createCache({
  key: 'muiltr',
});

const App = () => {
  // for get dir  left or right
  const dirPage = useGetDIR()

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#2576d2" },
      },
      direction: dirPage === "rtl" ? "rtl" : 'ltr'
    },
  );

  document.querySelector('html').setAttribute('dir', dirPage)


  return (
    <CacheProvider value={dirPage === 'rtl' ? cacheRtl : cacheLtr}>
      <ReplyMessageProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </ReplyMessageProvider>
    </CacheProvider>


  );
};

export default App;
